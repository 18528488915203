import { loadState } from '@nextcloud/initial-state';
import { getCurrentUser } from '@nextcloud/auth';
import { subscribe, unsubscribe } from '@nextcloud/event-bus';
import NcLoadingIcon from '@nextcloud/vue/dist/Components/NcLoadingIcon.js';
const {
  profileEnabled
} = loadState('user_status', 'profileEnabled', false);
export default {
  name: 'ProfileUserMenuEntry',
  components: {
    NcLoadingIcon
  },
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    href: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      profileEnabled,
      displayName: getCurrentUser().displayName,
      loading: false
    };
  },
  mounted() {
    subscribe('settings:profile-enabled:updated', this.handleProfileEnabledUpdate);
    subscribe('settings:display-name:updated', this.handleDisplayNameUpdate);
  },
  beforeDestroy() {
    unsubscribe('settings:profile-enabled:updated', this.handleProfileEnabledUpdate);
    unsubscribe('settings:display-name:updated', this.handleDisplayNameUpdate);
  },
  methods: {
    handleClick() {
      if (this.profileEnabled) {
        this.loading = true;
      }
    },
    handleProfileEnabledUpdate(profileEnabled) {
      this.profileEnabled = profileEnabled;
    },
    handleDisplayNameUpdate(displayName) {
      this.displayName = displayName;
    }
  }
};
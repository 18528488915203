import axios from '@nextcloud/axios';
import { emit, subscribe } from '@nextcloud/event-bus';
import { loadState } from '@nextcloud/initial-state';
import { generateOcsUrl } from '@nextcloud/router';
import { getCurrentUser } from '@nextcloud/auth';
import { getCapabilities } from '@nextcloud/capabilities';
import NcAvatar from '@nextcloud/vue/dist/Components/NcAvatar.js';
import NcHeaderMenu from '@nextcloud/vue/dist/Components/NcHeaderMenu.js';
import { getAllStatusOptions } from '../../../apps/user_status/src/services/statusOptionsService.js';
import ProfileUserMenuEntry from '../components/UserMenu/ProfileUserMenuEntry.vue';
import UserMenuEntry from '../components/UserMenu/UserMenuEntry.vue';
import logger from '../logger.js';

/**
 * @typedef SettingNavEntry
 * @property {string} id - id of the entry, used as HTML ID, for example, "settings"
 * @property {string} name - Label of the entry, for example, "Personal Settings"
 * @property {string} icon - Icon of the entry, for example, "/apps/settings/img/personal.svg"
 * @property {'settings'|'link'|'guest'} type - Type of the entry
 * @property {string} href - Link of the entry, for example, "/settings/user"
 * @property {boolean} active - Whether the entry is active
 * @property {number} order - Order of the entry
 * @property {number} unread - Number of unread pf this items
 * @property {string} classes - Classes for custom styling
 */

/** @type {Record<string, SettingNavEntry>} */
const settingsNavEntries = loadState('core', 'settingsNavEntries', []);
const {
  profile: profileEntry,
  ...otherEntries
} = settingsNavEntries;
const translateStatus = status => {
  const statusMap = Object.fromEntries(getAllStatusOptions().map(_ref => {
    let {
      type,
      label
    } = _ref;
    return [type, label];
  }));
  if (statusMap[status]) {
    return statusMap[status];
  }
  return status;
};
export default {
  name: 'UserMenu',
  components: {
    NcAvatar,
    NcHeaderMenu,
    ProfileUserMenuEntry,
    UserMenuEntry
  },
  data() {
    var _getCurrentUser, _getCurrentUser2;
    return {
      profileEntry,
      otherEntries,
      displayName: (_getCurrentUser = getCurrentUser()) === null || _getCurrentUser === void 0 ? void 0 : _getCurrentUser.displayName,
      userId: (_getCurrentUser2 = getCurrentUser()) === null || _getCurrentUser2 === void 0 ? void 0 : _getCurrentUser2.uid,
      isLoadingUserStatus: true,
      userStatus: {
        status: null,
        icon: null,
        message: null
      }
    };
  },
  computed: {
    translatedUserStatus() {
      return {
        ...this.userStatus,
        status: translateStatus(this.userStatus.status)
      };
    },
    avatarDescription() {
      const description = [t('core', 'Avatar of {displayName}', {
        displayName: this.displayName
      }), ...Object.values(this.translatedUserStatus).filter(Boolean)].join(' — ');
      return description;
    }
  },
  async created() {
    var _getCapabilities;
    if (!((_getCapabilities = getCapabilities()) !== null && _getCapabilities !== void 0 && (_getCapabilities = _getCapabilities.user_status) !== null && _getCapabilities !== void 0 && _getCapabilities.enabled)) {
      this.isLoadingUserStatus = false;
      return;
    }
    const url = generateOcsUrl('/apps/user_status/api/v1/user_status');
    try {
      const response = await axios.get(url);
      const {
        status,
        icon,
        message
      } = response.data.ocs.data;
      this.userStatus = {
        status,
        icon,
        message
      };
    } catch (e) {
      logger.error('Failed to load user status');
    }
    this.isLoadingUserStatus = false;
  },
  mounted() {
    subscribe('user_status:status.updated', this.handleUserStatusUpdated);
    emit('core:user-menu:mounted');
  },
  methods: {
    handleUserStatusUpdated(state) {
      if (this.userId === state.userId) {
        this.userStatus = {
          status: state.status,
          icon: state.icon,
          message: state.message
        };
      }
    }
  }
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("li", {
    staticClass: "menu-entry",
    attrs: {
      id: _vm.id
    }
  }, [_vm.href ? _c("a", {
    class: {
      active: _vm.active
    },
    attrs: {
      href: _vm.href
    },
    on: {
      click: function ($event) {
        if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) return null;
        return _vm.handleClick.apply(null, arguments);
      }
    }
  }, [_vm.loading ? _c("NcLoadingIcon", {
    staticClass: "menu-entry__loading-icon",
    attrs: {
      size: 18
    }
  }) : _c("img", {
    attrs: {
      src: _vm.cachedIcon,
      alt: ""
    }
  }), _vm._v("\n\t\t" + _vm._s(_vm.name) + "\n\t")], 1) : _c("button", [_c("img", {
    attrs: {
      src: _vm.cachedIcon,
      alt: ""
    }
  }), _vm._v("\n\t\t" + _vm._s(_vm.name) + "\n\t")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
import { render, staticRenderFns } from "./ProfileUserMenuEntry.vue?vue&type=template&id=1362c128&scoped=true"
import script from "./ProfileUserMenuEntry.vue?vue&type=script&lang=js"
export * from "./ProfileUserMenuEntry.vue?vue&type=script&lang=js"
import style0 from "./ProfileUserMenuEntry.vue?vue&type=style&index=0&id=1362c128&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1362c128",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/shgk/nextcloud/stable28/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1362c128')) {
      api.createRecord('1362c128', component.options)
    } else {
      api.reload('1362c128', component.options)
    }
    module.hot.accept("./ProfileUserMenuEntry.vue?vue&type=template&id=1362c128&scoped=true", function () {
      api.rerender('1362c128', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "core/src/components/UserMenu/ProfileUserMenuEntry.vue"
export default component.exports
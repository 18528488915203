// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("images/ui-icons_1d2d44_256x240.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("images/ui-icons_ffffff_256x240.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("images/ui-icons_ffd27a_256x240.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("images/ui-bg_diagonals-thick_20_666666_40x40.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("images/ui-bg_flat_10_000000_40x100.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Component containers
----------------------------------*/
.ui-widget-content {
  border: 1px solid var(--color-border);
  background: var(--color-main-background) none;
  color: var(--color-main-text);
}

.ui-widget-content a {
  color: var(--color-main-text);
}

.ui-widget-header {
  border: none;
  color: var(--color-main-text);
  background-image: none;
}

.ui-widget-header a {
  color: var(--color-main-text);
}

/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: 1px solid var(--color-border);
  background: var(--color-main-background) none;
  font-weight: bold;
  color: #555;
}

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
  color: #555;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  border: 1px solid #ddd;
  background: var(--color-main-background) none;
  font-weight: bold;
  color: var(--color-main-text);
}

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited {
  color: var(--color-main-text);
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border: 1px solid var(--color-primary-element);
  background: var(--color-main-background) none;
  font-weight: bold;
  color: var(--color-main-text);
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: var(--color-main-text);
}

/* Interaction Cues
----------------------------------*/
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border: 1px solid var(--color-main-background);
  background: var(--color-main-background) none;
  color: var(--color-text-light);
  font-weight: 600;
}

.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
  color: var(--color-text-lighter);
}

.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
  border: var(--color-error);
  background: var(--color-error) none;
  color: #ffffff;
}

.ui-state-error a,
.ui-widget-content .ui-state-error a,
.ui-widget-header .ui-state-error a {
  color: #ffffff;
}

.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
  color: #ffffff;
}

/* Icons
----------------------------------*/
.ui-state-default .ui-icon {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.ui-state-active .ui-icon {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.ui-state-highlight .ui-icon {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

.ui-icon.ui-icon-none {
  display: none;
}

/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
  background: #666666 url(${___CSS_LOADER_URL_REPLACEMENT_3___}) 50% 50% repeat;
  opacity: 0.5;
}

.ui-widget-shadow {
  margin: -5px 0 0 -5px;
  padding: 5px;
  background: #000000 url(${___CSS_LOADER_URL_REPLACEMENT_4___}) 50% 50% repeat-x;
  opacity: 0.2;
  border-radius: 5px;
}

/* Tabs customizations */
.ui-tabs {
  border: none;
}
.ui-tabs .ui-tabs-nav.ui-corner-all {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.ui-tabs .ui-tabs-nav {
  background: none;
  margin-bottom: 15px;
}
.ui-tabs .ui-tabs-nav .ui-state-default {
  border: none;
  border-bottom: 1px solid transparent;
  font-weight: normal;
  margin: 0 !important;
  padding: 0 !important;
}
.ui-tabs .ui-tabs-nav .ui-state-hover,
.ui-tabs .ui-tabs-nav .ui-state-active {
  border: none;
  border-bottom: 1px solid var(--color-main-text);
  color: var(--color-main-text);
}
.ui-tabs .ui-tabs-nav .ui-state-hover a, .ui-tabs .ui-tabs-nav .ui-state-hover a:link, .ui-tabs .ui-tabs-nav .ui-state-hover a:hover, .ui-tabs .ui-tabs-nav .ui-state-hover a:visited,
.ui-tabs .ui-tabs-nav .ui-state-active a,
.ui-tabs .ui-tabs-nav .ui-state-active a:link,
.ui-tabs .ui-tabs-nav .ui-state-active a:hover,
.ui-tabs .ui-tabs-nav .ui-state-active a:visited {
  color: var(--color-main-text);
}
.ui-tabs .ui-tabs-nav .ui-state-active {
  font-weight: bold;
}

/* Select menus */
.ui-autocomplete.ui-menu {
  padding: 0;
  /* scrolling starts from three items,
   * so hide overflow and scrollbars for a clean layout */
}
.ui-autocomplete.ui-menu.item-count-1, .ui-autocomplete.ui-menu.item-count-2 {
  overflow-y: hidden;
}
.ui-autocomplete.ui-menu .ui-menu-item a {
  color: var(--color-text-lighter);
  display: block;
  padding: 4px 4px 4px 14px;
}
.ui-autocomplete.ui-menu .ui-menu-item a.ui-state-focus, .ui-autocomplete.ui-menu .ui-menu-item a.ui-state-active {
  box-shadow: inset 4px 0 var(--color-primary-element);
  color: var(--color-main-text);
}
.ui-autocomplete.ui-widget-content {
  background: var(--color-main-background);
  border-top: none;
}
.ui-autocomplete.ui-corner-all {
  border-radius: 0;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
.ui-autocomplete .ui-state-hover, .ui-autocomplete .ui-widget-content .ui-state-hover,
.ui-autocomplete .ui-widget-header .ui-state-hover,
.ui-autocomplete .ui-state-focus,
.ui-autocomplete .ui-widget-content .ui-state-focus,
.ui-autocomplete .ui-widget-header .ui-state-focus {
  border: 1px solid transparent;
  background: inherit;
  color: var(--color-primary-element);
}
.ui-autocomplete .ui-menu-item a {
  border-radius: 0 !important;
}

.ui-button.primary {
  background-color: var(--color-primary-element);
  color: var(--color-primary-element-text);
  border: 1px solid var(--color-primary-element-text);
}

.ui-button:hover {
  font-weight: bold !important;
}

/* DRAGGABLE */
.ui-draggable-handle,
.ui-selectable {
  touch-action: pan-y;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

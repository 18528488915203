import { loadState } from '@nextcloud/initial-state';
import NcLoadingIcon from '@nextcloud/vue/dist/Components/NcLoadingIcon.js';
const versionHash = loadState('core', 'versionHash', '');
export default {
  name: 'UserMenuEntry',
  components: {
    NcLoadingIcon
  },
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    href: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    cachedIcon() {
      return "".concat(this.icon, "?v=").concat(versionHash);
    }
  },
  methods: {
    handleClick() {
      this.loading = true;
    }
  }
};
import { loadState } from '@nextcloud/initial-state';
import { subscribe, unsubscribe } from '@nextcloud/event-bus';
import NcActions from '@nextcloud/vue/dist/Components/NcActions.js';
import NcActionLink from '@nextcloud/vue/dist/Components/NcActionLink.js';
export default {
  name: 'AppMenu',
  components: {
    NcActions,
    NcActionLink
  },
  data() {
    return {
      apps: loadState('core', 'apps', {}),
      appLimit: 0,
      observer: null
    };
  },
  computed: {
    appList() {
      return Object.values(this.apps);
    },
    mainAppList() {
      return this.appList.slice(0, this.appLimit);
    },
    popoverAppList() {
      return this.appList.slice(this.appLimit);
    },
    appLabel() {
      return app => app.name + (app.active ? ' (' + t('core', 'Currently open') + ')' : '') + (app.unread > 0 ? ' (' + n('core', '{count} notification', '{count} notifications', app.unread, {
        count: app.unread
      }) + ')' : '');
    }
  },
  mounted() {
    this.observer = new ResizeObserver(this.resize);
    this.observer.observe(this.$el);
    this.resize();
    subscribe('nextcloud:app-menu.refresh', this.setApps);
  },
  beforeDestroy() {
    this.observer.disconnect();
    unsubscribe('nextcloud:app-menu.refresh', this.setApps);
  },
  methods: {
    setNavigationCounter(id, counter) {
      this.$set(this.apps[id], 'unread', counter);
    },
    setApps(_ref) {
      let {
        apps
      } = _ref;
      this.apps = apps;
    },
    resize() {
      const availableWidth = this.$el.offsetWidth;
      let appCount = Math.floor(availableWidth / 50) - 1;
      const popoverAppCount = this.appList.length - appCount;
      if (popoverAppCount === 1) {
        appCount--;
      }
      if (appCount < 1) {
        appCount = 0;
      }
      this.appLimit = appCount;
    }
  }
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("li", {
    staticClass: "menu-entry",
    attrs: {
      id: _vm.id
    }
  }, [_c(_vm.profileEnabled ? "a" : "span", {
    tag: "component",
    staticClass: "menu-entry__wrapper",
    class: {
      active: _vm.active,
      "menu-entry__wrapper--link": _vm.profileEnabled
    },
    attrs: {
      href: _vm.profileEnabled ? _vm.href : undefined
    },
    on: {
      click: function ($event) {
        if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) return null;
        return _vm.handleClick.apply(null, arguments);
      }
    }
  }, [_c("span", {
    staticClass: "menu-entry__content"
  }, [_c("span", {
    staticClass: "menu-entry__displayname"
  }, [_vm._v(_vm._s(_vm.displayName))]), _vm._v(" "), _vm.loading ? _c("NcLoadingIcon", {
    attrs: {
      size: 18
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm.profileEnabled ? _c("span", [_vm._v(_vm._s(_vm.name))]) : _vm._e()])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("nav", {
    staticClass: "app-menu",
    attrs: {
      "aria-label": _vm.t("core", "Applications menu")
    }
  }, [_c("ul", {
    staticClass: "app-menu-main"
  }, _vm._l(_vm.mainAppList, function (app) {
    return _c("li", {
      key: app.id,
      staticClass: "app-menu-entry",
      class: {
        "app-menu-entry__active": app.active
      },
      attrs: {
        "data-app-id": app.id
      }
    }, [_c("a", {
      class: {
        "has-unread": app.unread > 0
      },
      attrs: {
        href: app.href,
        "aria-label": _vm.appLabel(app),
        title: app.name,
        "aria-current": app.active ? "page" : false,
        target: app.target ? "_blank" : undefined,
        rel: app.target ? "noopener noreferrer" : undefined
      }
    }, [_c("img", {
      attrs: {
        src: app.icon,
        alt: ""
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "app-menu-entry--label"
    }, [_vm._v("\n\t\t\t\t\t" + _vm._s(app.name) + "\n\t\t\t\t\t"), app.unread > 0 ? _c("span", {
      staticClass: "hidden-visually unread-counter"
    }, [_vm._v(_vm._s(app.unread))]) : _vm._e()])])]);
  }), 0), _vm._v(" "), _c("NcActions", {
    staticClass: "app-menu-more",
    attrs: {
      "aria-label": _vm.t("core", "More apps")
    }
  }, _vm._l(_vm.popoverAppList, function (app) {
    return _c("NcActionLink", {
      key: app.id,
      staticClass: "app-menu-popover-entry",
      attrs: {
        "aria-label": _vm.appLabel(app),
        "aria-current": app.active ? "page" : false,
        href: app.href
      },
      scopedSlots: _vm._u([{
        key: "icon",
        fn: function () {
          return [_c("div", {
            staticClass: "app-icon",
            class: {
              "has-unread": app.unread > 0
            }
          }, [_c("img", {
            attrs: {
              src: app.icon,
              alt: ""
            }
          })])];
        },
        proxy: true
      }], null, true)
    }, [_vm._v("\n\t\t\t" + _vm._s(app.name) + "\n\t\t\t"), app.unread > 0 ? _c("span", {
      staticClass: "hidden-visually unread-counter"
    }, [_vm._v(_vm._s(app.unread))]) : _vm._e()]);
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-menu[data-v-7b1cbf70] {
  margin-right: 12px;
}
.user-menu[data-v-7b1cbf70] .header-menu__trigger {
  opacity: 1 !important;
}
.user-menu[data-v-7b1cbf70] .header-menu__trigger:focus-visible .user-menu__avatar {
  border: 2px solid var(--color-primary-element);
}
.user-menu[data-v-7b1cbf70] .header-menu__carret {
  display: none !important;
}
.user-menu[data-v-7b1cbf70] .header-menu__content {
  width: fit-content !important;
}
.user-menu__avatar[data-v-7b1cbf70]:active, .user-menu__avatar[data-v-7b1cbf70]:focus, .user-menu__avatar[data-v-7b1cbf70]:hover {
  border: 2px solid var(--color-primary-element-text);
}
.user-menu ul[data-v-7b1cbf70] {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.user-menu ul[data-v-7b1cbf70] li a,
.user-menu ul[data-v-7b1cbf70] li button {
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  height: var(--header-menu-item-height);
  color: var(--color-main-text);
  padding: 10px 8px;
  box-sizing: border-box;
  white-space: nowrap;
  position: relative;
  width: 100%;
}
.user-menu ul[data-v-7b1cbf70] li a:hover,
.user-menu ul[data-v-7b1cbf70] li button:hover {
  background-color: var(--color-background-hover);
}
.user-menu ul[data-v-7b1cbf70] li a:focus-visible,
.user-menu ul[data-v-7b1cbf70] li button:focus-visible {
  background-color: var(--color-background-hover) !important;
  box-shadow: inset 0 0 0 2px var(--color-primary-element) !important;
  outline: none !important;
}
.user-menu ul[data-v-7b1cbf70] li a:active:not(:focus-visible), .user-menu ul[data-v-7b1cbf70] li a.active:not(:focus-visible),
.user-menu ul[data-v-7b1cbf70] li button:active:not(:focus-visible),
.user-menu ul[data-v-7b1cbf70] li button.active:not(:focus-visible) {
  background-color: var(--color-primary-element);
  color: var(--color-primary-element-text);
}
.user-menu ul[data-v-7b1cbf70] li a:active:not(:focus-visible) img, .user-menu ul[data-v-7b1cbf70] li a.active:not(:focus-visible) img,
.user-menu ul[data-v-7b1cbf70] li button:active:not(:focus-visible) img,
.user-menu ul[data-v-7b1cbf70] li button.active:not(:focus-visible) img {
  filter: var(--primary-invert-if-dark);
}
.user-menu ul[data-v-7b1cbf70] li a span,
.user-menu ul[data-v-7b1cbf70] li button span {
  padding-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;
}
.user-menu ul[data-v-7b1cbf70] li a img,
.user-menu ul[data-v-7b1cbf70] li button img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.user-menu ul[data-v-7b1cbf70] li a img,
.user-menu ul[data-v-7b1cbf70] li button img {
  filter: var(--background-invert-if-dark);
}
.user-menu ul[data-v-7b1cbf70] li button {
  background-color: transparent;
  border: none;
  font-weight: normal;
  margin: 0;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

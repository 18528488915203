var render = function render() {
  var _vm$contact$topAction;
  var _vm = this,
    _c = _vm._self._c;
  return _c("li", {
    staticClass: "contact"
  }, [_c("NcAvatar", {
    staticClass: "contact__avatar",
    attrs: {
      size: 44,
      user: _vm.contact.isUser ? _vm.contact.uid : undefined,
      "is-no-user": !_vm.contact.isUser,
      "disable-menu": true,
      "display-name": _vm.contact.avatarLabel,
      "preloaded-user-status": _vm.preloadedUserStatus
    }
  }), _vm._v(" "), _c("a", {
    staticClass: "contact__body",
    attrs: {
      href: _vm.contact.profileUrl || ((_vm$contact$topAction = _vm.contact.topAction) === null || _vm$contact$topAction === void 0 ? void 0 : _vm$contact$topAction.hyperlink)
    }
  }, [_c("div", {
    staticClass: "contact__body__full-name"
  }, [_vm._v(_vm._s(_vm.contact.fullName))]), _vm._v(" "), _vm.contact.lastMessage ? _c("div", {
    staticClass: "contact__body__last-message"
  }, [_vm._v(_vm._s(_vm.contact.lastMessage))]) : _vm._e(), _vm._v(" "), _vm.contact.statusMessage ? _c("div", {
    staticClass: "contact__body__status-message"
  }, [_vm._v(_vm._s(_vm.contact.statusMessage))]) : _c("div", {
    staticClass: "contact__body__email-address"
  }, [_vm._v(_vm._s(_vm.contact.emailAddresses[0]))])]), _vm._v(" "), _vm.actions.length ? _c("NcActions", {
    attrs: {
      inline: _vm.contact.topAction ? 1 : 0
    }
  }, [_vm._l(_vm.actions, function (action, idx) {
    return [action.hyperlink !== "#" ? _c("NcActionLink", {
      key: idx,
      staticClass: "other-actions",
      attrs: {
        href: action.hyperlink
      },
      scopedSlots: _vm._u([{
        key: "icon",
        fn: function () {
          return [_c("img", {
            staticClass: "contact__action__icon",
            attrs: {
              "aria-hidden": "true",
              src: action.icon
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_vm._v("\n\t\t\t\t" + _vm._s(action.title) + "\n\t\t\t")]) : _c("NcActionText", {
      key: idx,
      staticClass: "other-actions",
      scopedSlots: _vm._u([{
        key: "icon",
        fn: function () {
          return [_c("img", {
            staticClass: "contact__action__icon",
            attrs: {
              "aria-hidden": "true",
              src: action.icon
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_vm._v("\n\t\t\t\t" + _vm._s(action.title) + "\n\t\t\t")])];
  })], 2) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-menu[data-v-5dc6d664] {
  width: 100%;
  display: flex;
  flex-shrink: 1;
  flex-wrap: wrap;
}
.app-menu-main[data-v-5dc6d664] {
  display: flex;
  flex-wrap: nowrap;
}
.app-menu-main .app-menu-entry[data-v-5dc6d664] {
  width: 50px;
  height: 50px;
  position: relative;
  display: flex;
}
.app-menu-main .app-menu-entry.app-menu-entry__active[data-v-5dc6d664] {
  opacity: 1;
}
.app-menu-main .app-menu-entry.app-menu-entry__active[data-v-5dc6d664]::before {
  content: " ";
  position: absolute;
  pointer-events: none;
  border-bottom-color: var(--color-main-background);
  transform: translateX(-50%);
  width: 12px;
  height: 5px;
  border-radius: 3px;
  background-color: var(--color-primary-text);
  left: 50%;
  bottom: 6px;
  display: block;
  transition: all 0.1s ease-in-out;
  opacity: 1;
}
.app-menu-main .app-menu-entry.app-menu-entry__active .app-menu-entry--label[data-v-5dc6d664] {
  font-weight: bold;
}
.app-menu-main .app-menu-entry a[data-v-5dc6d664] {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  margin: 2px;
  color: var(--color-primary-text);
  position: relative;
}
.app-menu-main .app-menu-entry img[data-v-5dc6d664] {
  transition: margin 0.1s ease-in-out;
  width: 20px;
  height: 20px;
  padding: calc((100% - 20px) / 2);
  box-sizing: content-box;
  filter: var(--background-image-invert-if-bright);
}
.app-menu-main .app-menu-entry .app-menu-entry--label[data-v-5dc6d664] {
  opacity: 0;
  position: absolute;
  font-size: 12px;
  color: var(--color-primary-text);
  text-align: center;
  left: 50%;
  top: 45%;
  display: block;
  min-width: 100%;
  transform: translateX(-50%);
  transition: all 0.1s ease-in-out;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: -0.5px;
}
.app-menu-main .app-menu-entry[data-v-5dc6d664]:hover, .app-menu-main .app-menu-entry[data-v-5dc6d664]:focus-within {
  opacity: 1;
}
.app-menu-main .app-menu-entry:hover .app-menu-entry--label[data-v-5dc6d664], .app-menu-main .app-menu-entry:focus-within .app-menu-entry--label[data-v-5dc6d664] {
  opacity: 1;
  font-weight: bolder;
  bottom: 0;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.app-menu-main[data-v-5dc6d664]:hover, .app-menu-main[data-v-5dc6d664]:focus-within,
.app-menu-main .app-menu-entry[data-v-5dc6d664]:hover,
.app-menu-main .app-menu-entry[data-v-5dc6d664]:focus {
  opacity: 1;
}
.app-menu-main:hover img[data-v-5dc6d664], .app-menu-main:focus-within img[data-v-5dc6d664],
.app-menu-main .app-menu-entry:hover img[data-v-5dc6d664],
.app-menu-main .app-menu-entry:focus img[data-v-5dc6d664] {
  margin-top: -8px;
}
.app-menu-main:hover .app-menu-entry--label[data-v-5dc6d664], .app-menu-main:focus-within .app-menu-entry--label[data-v-5dc6d664],
.app-menu-main .app-menu-entry:hover .app-menu-entry--label[data-v-5dc6d664],
.app-menu-main .app-menu-entry:focus .app-menu-entry--label[data-v-5dc6d664] {
  opacity: 1;
  bottom: 0;
}
.app-menu-main[data-v-5dc6d664]:hover::before, .app-menu-main:hover .app-menu-entry[data-v-5dc6d664]::before, .app-menu-main[data-v-5dc6d664]:focus-within::before, .app-menu-main:focus-within .app-menu-entry[data-v-5dc6d664]::before,
.app-menu-main .app-menu-entry[data-v-5dc6d664]:hover::before,
.app-menu-main .app-menu-entry:hover .app-menu-entry[data-v-5dc6d664]::before,
.app-menu-main .app-menu-entry[data-v-5dc6d664]:focus::before,
.app-menu-main .app-menu-entry:focus .app-menu-entry[data-v-5dc6d664]::before {
  opacity: 0;
}
[data-v-5dc6d664] .app-menu-more .button-vue--vue-tertiary {
  opacity: 0.7;
  margin: 3px;
  filter: var(--background-image-invert-if-bright);
  /* Remove all background and align text color if not expanded */
}
[data-v-5dc6d664] .app-menu-more .button-vue--vue-tertiary:not([aria-expanded=true]) {
  color: var(--color-primary-element-text);
}
[data-v-5dc6d664] .app-menu-more .button-vue--vue-tertiary:not([aria-expanded=true]):hover {
  opacity: 1;
  background-color: transparent !important;
}
[data-v-5dc6d664] .app-menu-more .button-vue--vue-tertiary:focus-visible {
  opacity: 1;
  outline: none !important;
}
.app-menu-popover-entry .app-icon[data-v-5dc6d664] {
  position: relative;
  height: 44px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Icons are bright so invert them if bright color theme == bright background is used */
  filter: var(--background-invert-if-bright);
}
.app-menu-popover-entry .app-icon.has-unread[data-v-5dc6d664]::after {
  background-color: var(--color-main-text);
}
.app-menu-popover-entry .app-icon img[data-v-5dc6d664] {
  width: 20px;
  height: 20px;
}
.has-unread[data-v-5dc6d664]::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: var(--color-primary-element-text);
  border-radius: 50%;
  position: absolute;
  display: block;
  top: 10px;
  right: 10px;
}
.unread-counter[data-v-5dc6d664] {
  display: none;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-entry__wrapper[data-v-1362c128] {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start !important;
  padding: 10px 12px 5px 12px !important;
  height: var(--header-menu-item-height);
  color: var(--color-text-maxcontrast);
}
.menu-entry__wrapper--link[data-v-1362c128] {
  height: calc(var(--header-menu-item-height) * 1.5) !important;
  color: var(--color-main-text);
}
.menu-entry__content[data-v-1362c128] {
  display: inline-flex;
  gap: 0 10px;
}
.menu-entry__displayname[data-v-1362c128] {
  font-weight: bold;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
